
export const SET_APP_ERROR = 'APP/SET_ERROR';
export const RESET_APP_ERROR = 'APP/RESET_ERROR';
export const SET_APP_SECTION = 'APP/SECTION/SET';
export const SET_APP_LOADING_STATE = 'APP/SET_LOADING_STATE';
export const SET_APP_SECTION_LOADING_STATE = 'APP/SECTION/SET_LOADING_STATE'

export const SET_USER = 'USER/SET';
export const UPDATE_USER = 'USER/UPDATE';
export const SET_USER_PURCHASED_PRODUCTS = 'USER/SET_PURCHASED_PRODUCTS';

export const SET_LISTS = 'LISTS/SET';
export const ADD_LIST = 'LISTS/ADD_LIST'

export const SET_DRAFT = 'DRAFT/SET';
export const REMOVE_DRAFT = 'DRAFT/REMOVE';
export const CREATE_DRAFT = 'DRAFT/CREATE';
export const SET_DRAFT_PRODUCTS = 'DRAFT/SET_PRODUCTS';
export const ADD_DRAFT_PRODUCT = 'DRAFT/ADD_PRODUCT';
export const REMOVE_DRAFT_PRODUCT = 'DRAFT/REMOVE_PRODUCT'
export const SELECT_DRAFT_PRODUCT = 'DRAFT/SELECT_PRODUCT';
export const UNSELECT_DRAFT_PRODUCT = 'DRAFT/UNSELECT_PRODUCT';
